
<template>
  <div>
    <AuthSightCompany @sightNumGet="setSightNum" />
  </div>
</template>

<script>
import AuthSightCompany from "../components/AuthSightCompany";
export default {
  components: {
    AuthSightCompany
  },
  data() {
    return {
      activeName: "sight",
      sightNum: 0,
      bizNum: 0,
      newsNum: 0,
      invoiceNum: 0
    };
  },
  methods: {
    setSightNum(data) {
      this.sightNum = data;
    }
  }
};
</script>

<style scoped>
i {
  color: red;
}
</style>