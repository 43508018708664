<template>
  <div>
    <div v-for="(item,i) in sightCompanies" :key="i" class="sightbox">
      <h3 class="center-g">景区公司信息</h3>
      <el-descriptions :title="item.name" :column="4" border style="margin:20px 0">
        <template slot="extra" style="display:flex">
          <el-button type="success" size="small" @click="approveSightCompanyFn(item)">同意</el-button>
          <el-button type="danger" plain size="small" @click="rejectSightCompanyFn(item)">拒绝</el-button>
        </template>
        <el-descriptions-item v-for="(value, key) in headName" :key="key" :label="value">
          <img
            :src="item[key]"
            alt
            v-if="key=='license'"
            width="40px"
            height="40px"
            @click="previewImg(item.license)"
          />
          <div v-else>{{item[key]}}</div>
        </el-descriptions-item>
      </el-descriptions>

      <el-descriptions :title="item.sight.name" :column="4" border style="margin:20px 0">
        <template slot="extra" style="display:flex">
          <el-button type="primary" size="small" @click="toMapview(item)">地图查看</el-button>
        </template>
        <el-descriptions-item
          v-for="(value, key) in sightHeadName"
          :key="key"
          :label="value"
        >{{item.sight[key]}}</el-descriptions-item>
      </el-descriptions>
    </div>

    <!-- <el-dialog :visible.sync="showMap" top="2vh" destroy-on-close>
      <mapview :positions="positionsforMap" v-if="showMap" />
    </el-dialog> -->
    <!-- 照片放大弹出框 -->
    <el-dialog :visible.sync="picShow" width="900px">
      <img :src="imgForPreview" width="800px" alt />
    </el-dialog>
  </div>
</template>

<script>
import { getSightCompaniesForAuth, auditSightCompany } from "../api/api";
// import mapview from "../components/MapView";
import { mapGetters } from "vuex";
export default {
  // components: {
  //   mapview
  // },
  data() {
    return {
      showMap: false,
      positionsforMap: {},
      sightCompanies: [],
      imgForPreview: "",
      picShow: false,
      headName: {
        id: "编号",
        idSight: "景区ID",
        idUser: "管理员ID",
        nickName: "管理员昵称",
        regphoneUser: "注册电话",
        contactPerson: "联系人",
        cashIdUser: "收款人ID",
        name: "公司名称",
        noteNameBiz: "公司简称",
        province: "省",
        city: "市",
        district: "区县",
        address: "地址",
        adcode: "区码",
        companyCode: "统一代码",
        license: "营业执照"
      },
      sightHeadName: {
        id: "景区ID",
        name: "景区名称",
        province: "省",
        city: "市",
        district: "区县",
        address: "地址",
        typeName: "类别",
        idSightCompany: "公司ID"
      }
    };
  },
  mounted() {
    this.getSightcompanies();
  },
  computed: {
    ...mapGetters(["getFirstAuth"])
  },
  methods: {
    getSightcompanies() {
      const that = this;
      getSightCompaniesForAuth({}).then(res => {
        that.sightCompanies = res.data;
        that.getFirstAuth.sightCompany = that.sightCompanies.length;
        that.$store.dispatch("setFirstAuth", that.getFirstAuth);
        that.$emit("sightNumGet", that.sightCompanies.length);
      });
    },

    toMapview(item) {
      this.positionsforMap = item.positions;
      this.showMap = true;
    },

    closeMap() {
      this.showMap = false;
    },

    previewImg(item) {
      this.imgForPreview = item.replace(".thumb", "");
      this.picShow = true;
    },
    approveSightCompanyFn(item) {
      const that = this;
      this.$confirm("确定审核通过?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          auditSightCompany({
            id: item.id,
            type: 1
          }).then(res => {
            if (res.result) {
              that.$message({
                type: "success",
                message: "审核通过"
              });
              that.getSightcompanies();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消"
          });
        });
    },

    rejectSightCompanyFn(item) {
      const that = this;
      this.$prompt("请输入拒绝的原因", "拒绝", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^\S{1,}$/,
        inputErrorMessage: "请输入拒绝的原因",
        closeOnClickModal: false
      })
        .then(({ value }) => {
          auditSightCompany({
            id: item.id,
            type: 2,
            value: value
          }).then(res => {
            if (res.result) {
              that.$message({
                type: "warning",
                message: "审核未通过"
              });
              that.getSightcompanies();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消"
          });
        });
    }
  }
};
</script>

<style scoped>
.sightbox {
  padding: 30px;
  margin: 30px;
  border: 1px solid #555;
  border-radius: 10px;
}
.itembox {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  border: 1px dashed #999;
  border-radius: 10px;
  padding: 30px;
  margin: 20px;
}

.itembox div {
  margin-right: 30px;
  margin-bottom: 30px;
}
</style>